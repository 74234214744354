/**
 * @Package: Shipping - Logistics & Transport HTML Template
 * @Since: Shipping 1.0
 * This file is part of Shipping - Logistics & Transport HTML Template package.
 */

jQuery(function ($) {

  'use strict';

  var SHIPPING_SETTINGS = window.SHIPPING_SETTINGS || {};

  /*--------------------------------
      InViewPort Animations
   --------------------------------*/

  SHIPPING_SETTINGS.inViewPortAnimation = function () {

    if ($.isFunction($.fn.viewportChecker)) {

      $('.inviewport').addClass("hiddenthis").viewportChecker({
        classToAdd: 'visiblethis',
        offset: 0,
        callbackFunction: function (elem) {
          var effect = $(elem).attr("data-effect");
          $(elem).addClass(effect);
        }
      });

    }

  }

  /*--------------------------------
      Mobile Menu
   --------------------------------*/

  SHIPPING_SETTINGS.mobileMenu = function () {

    var mobile_str = "";
    $(".menu-ul").each(function () {
      mobile_str += $(this).html();
    });

    $(".menu-mobile ul.menu").html(mobile_str);

    $(".menu-toggle").on('click', function () {
      $(".menu-mobile.cssmenu").toggleClass("open");
      $(this).toggleClass("mdi-menu mdi-close");
    });

    $('.menu-mobile.cssmenu li.has-sub a').on('click', function (e) {
      $(this).parent().children("ul").toggleClass("open");
      $(this).find("i").toggleClass("open");
      e.stopPropagation();
    });
  }



  /*--------------------------------
      Contact AJAX Form
   --------------------------------*/

  SHIPPING_SETTINGS.validateContactForm = function () {

    $("#c_name").on('keyup', function () {
      var name = $("#c_name").val();
      if (name == null || name == "") {
        $("#c_name").removeClass("green");
      } else {
        $("#c_name").addClass("green");
      }
      enable_form();
    });

    $("#c_email").on('keyup', function () {
      var email = $("#c_email").val();
      var atpos = email.indexOf("@");
      var dotpos = email.lastIndexOf(".");

      if (email == null || email == "" || atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
        $("#c_email").removeClass("green");
      } else {
        $("#c_email").addClass("green");
      }
      enable_form();
    });


    $("#c_message").on('keyup', function () {
      var message = $("#c_message").val();
      if (message == null || message == "" || message.length < 9) {
        $("#c_message").removeClass("green");
      } else {
        $("#c_message").addClass("green");
      }

      enable_form();

    });

  }


  SHIPPING_SETTINGS.sendMessageAJAX = function () {

    $("#c_send").on('click', function () {
      if ($(this).hasClass("disabled")) {
        $("#response_email").html("Please Fill in your details correctly and try again");
      } else {

        var email = $('#c_email').val();
        var name = $('#c_name').val();
        var msg = $('#c_message').val();

        var xmlhttp;
        if (window.XMLHttpRequest) { // code for IE7+, Firefox, Chrome, Opera, Safari
          xmlhttp = new XMLHttpRequest();
        } else { // code for IE6, IE5
          xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        xmlhttp.onreadystatechange = function () {
          if (xmlhttp.readyState == 1) {
            $("#response_email").html("Sending...");
          }
          if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
            $("#response_email").html(xmlhttp.responseText);
          }
        }
        xmlhttp.open("POST", "send_email.php", true);
        xmlhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xmlhttp.send("email=" + encodeURIComponent(email) + "&name=" + encodeURIComponent(name) + "&msg=" + encodeURIComponent(msg));
        return false;

      }
    });

  }


  SHIPPING_SETTINGS.openModal = function () {

    $("#privacy").on('click', function (e) {
      console.log('privacy click');
      e.preventDefault();

      if ($(".modaljs").length === 0) {
        $("#modal").load("privacy.html");
        setTimeout(() => {
          $(".modaljs").css("display", "block");
        }, 100);
      } else {
        $(".modaljs").css("display", "block");
      }

    });

    $(".modaljs .modaljs--close").on('click', function (e) {
      e.preventDefault();
      $(".modaljs").css("display", "none");
    });

    $(window).on('click', function (e) {
      console.log('window.click', e.target, $(".modaljs")[0]);
      console.log('equals');
      if (e.target == $(".modaljs")[0]) {
        console.log('equals');
        $(".modaljs").css("display", "none");
      }
    });

  }


  function enable_form() {
    if ($("#c_name").hasClass("green") &&
      $("#c_email").hasClass("green") &&
      $("#c_message").hasClass("green")) {
      $("#c_send").removeClass("disabled");
    } else {
      $("#c_send").addClass("disabled");
    }

  }

  /******************************
   initialize respective scripts 
   *****************************/
  $(document).ready(function () {
    SHIPPING_SETTINGS.mobileMenu();
    SHIPPING_SETTINGS.validateContactForm();
    SHIPPING_SETTINGS.sendMessageAJAX();
    SHIPPING_SETTINGS.inViewPortAnimation();
    SHIPPING_SETTINGS.openModal();

    $("#worldwide").appear();
    $("body").on("appear", "#worldwide", function () {
      // const tl1 = gsap.timeline({ defaults: { ease: 'power1.out' } })
      gsap.to('.map-locations', { y: '0%', duration: 1 });
    });

    $('#main-video__container video').on('ended', onMainVideoStopped);
    $('#main-video__container video').on('play', onMainVideoPlayed).each(function () {
      this.play()
    });

    $('.main-video__actions--skip').on('click', () => {
      $('#main-video__player').get(0).pause();
      onMainVideoStopped();
    })
  });

  function onMainVideoPlayed() {
    $('body').css({ "overflow": "hidden" });
    $('body > section#main-video__container').show();
    $('body > section:not(#main-video__container)').hide();
    setTimeout(() => {
      $('body > section:not(#main-video__container)').show();
      $('body > section:not(#main-video__container)').css({ "visibility": "hidden" });
    }, 500);
  }

  function onMainVideoStopped() {
    $('body').css({ "overflow": "" });
    $('body > section#main-video__container').hide();
    gsap.to($('body > section:not(#main-video__container)'), 1, { autoAlpha: 1 });
    // gsap.to('.map-locations', { y: '0%', duration: 1 });
  }

  $(window).scroll(function () { });

  var $button = $.backToTop();
});
